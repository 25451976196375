define("discourse/plugins/discourse-eotagging/discourse/connectors/topic-category/eotagging", ["exports", "discourse/models/user", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _user, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      const current_user = _user.default.current();

      // permissions check!
      if (!current_user) {
        this.set('user_can_edit', false);
        return;
      }

      // find out if the current category is editable
      (0, _ajax.ajax)('/eotag/fetch_categories', {
        type: "GET"
      }).then(response => {
        if (response.categories) {
          const categories = response.categories.split(",");
          categories.forEach(cat => {
            if (this.category) {
              if (cat == this.category.id) this.set('category_is_editable', true);
            }
          });
        }
      }).catch(_ajaxError.popupAjaxError);

      // find out if the current user is in the right group(s)
      (0, _ajax.ajax)('/eotag/fetch_groups', {
        type: "GET"
      }).then(response => {
        if (response.groups) {
          const auth_groups = response.groups.split(",");
          const user_groups = current_user.groups;
          auth_groups.forEach(group => {
            user_groups.forEach(ugroup => {
              if (group == ugroup.name) this.set('user_can_edit', true);
            });
          });
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    actions: {
      toggleEotEdit(topic_tags) {
        this.set('editTopicTagsOnly', true);
      },
      saveTopicTags(topic_id, tag_list) {
        this.set('editTopicTagsOnly', false);
        if (!tag_list.length) tag_list = "NULL_TAGS";
        (0, _ajax.ajax)('/eotag/save/' + _user.default.current().id + '/' + topic_id + '/' + tag_list.toString(), {
          type: "GET"
        }).then(response => {}).catch(_ajaxError.popupAjaxError);
      }
    }
  };
});