define("discourse/plugins/discourse-eotagging/discourse/controllers/admin-plugins-eotagging", ["exports", "ember", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _ember, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Controller.extend({
    init() {
      this._super();
      (0, _ajax.ajax)('/eotag/fetch_groups', {
        type: "GET"
      }).then(response => {
        this.set('which_groups', response.groups);
      }).catch(_ajaxError.popupAjaxError);
      (0, _ajax.ajax)('/eotag/fetch_categories', {
        type: "GET"
      }).then(response => {
        if (response.categories) {
          this.set('which_categories', response.categories);
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    actions: {
      saveGroups() {
        let data = this.get('which_groups');
        (0, _ajax.ajax)('/eotag/save_groups/' + data, {
          type: "GET"
        }).then(response => {}).catch(_ajaxError.popupAjaxError);
      },
      saveCategories() {
        let data = this.get('which_categories');
        (0, _ajax.ajax)('/eotag/save_categories/' + data, {
          type: "GET"
        }).then(response => {}).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});